<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Xичээлийн сорил/ шалгалтууд
        </h5>
        <p class="text-body">Багшийн xичээлтэй xолбоотой сорил/ шалгалтууд.</p>
      </v-col>
    </v-row>
    <!-- <section v-if="lessons && lessons.length > 0">
      <v-row v-for="lesson in lessons" :key="lesson.id">
        <div class="d-flex" v-if="lesson.exams && lesson.exams.length > 0">
          <v-card
            v-for="exam in lesson.exams"
            :key="exam.id"
            class="card-shadow border-radius-xl mr-10"
            :ripple="false"
            width="400"
          >
            <div class="d-flex">
              <div class="px-4 pt-4">
                <div class="my-auto">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ exam.title }}
                  </h6>
                  <p class="blue--text">{{ lesson.name }}</p>
                </div>
              </div>
            </div>

            <v-card-actions class="d-flex flex-row justify-end px-4 py-0">
              <h1 class="mr-1">{{ exam.selectedExam.duration }}</h1>
              <span v-if="exam.selectedExam.duration">мин</span>
              <span
                v-else
                class="text-alert font-weight-bold"
                style="color: red"
                >Xугацаа оруулаагүй</span
              >
              <v-spacer></v-spacer>
              <h1
                class="mr-1"
                v-if="exam.selectedExam.finalScore"
                style="color: red"
              >
                {{ exam.selectedExam.finalScore }}
              </h1>
              <span v-if="exam.selectedExam.finalScore">оноо</span>
            </v-card-actions>
            <p class="text-sm mt-4 text-body" v-if="exam.description">
              {{ exam.description }}
            </p>
            <hr class="horizontal dark my-4" />
            <v-card-actions class="d-flex flex-row justify-end px-4 pb-4">
              <h1 class="mr-1" v-if="exam.results && exam.results.totalScore">
                {{ exam.results.totalScore }}
              </h1>

              <span v-if="exam.results && exam.results.totalScore">оноо</span>
              <v-spacer></v-spacer>
              <v-btn
                color="green"
                v-if="exam.results && exam.results.examTaken"
                text
                @click="_showResult(exam)"
                class="border-radius-sm text-xs me-1 shadow-none font-weight-bold px-3 py-1"
                elevation="0"
                :ripple="false"
                >Хариулт харах</v-btn
              >
              <v-btn
                v-else
                @click="showWarningAlert(exam)"
                small
                class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 btn-warning bg-gradient-warning"
                elevation="0"
                :ripple="false"
                >Сорил өгөx</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-row>
    </section>
    <section v-if="selectedLessons && selectedLessons.length > 0">
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="lesson in selectedLessons"
          :key="lesson.id"
        >
          <StudentsLessonCard
            :lesson="lesson"
            @click="_detail(lesson)"
            v-bind="$attrs"
          ></StudentsLessonCard>
        </v-col>
      </v-row>
    </section> -->
    <section v-if="teacherSorils && teacherSorils.length > 0">
      <v-row>
        <v-col lg="4" md="6" cols="12" v-for="assignment in teacherSorils" :key="assignment.id">
          <v-card class="card-shadow border-radius-xl" :ripple="false">
            <div class="px-4 py-4">
              <div class="d-flex">
                <div class="my-auto">
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }}
                    <!-- <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >, ЭЕШ-шалгалт</small
                    >
                    <small
                      v-else
                      style="color: red; font-weight: normal !important"
                      >, Стандарт шалгалт</small
                    > -->
                  </h6>
                  <small v-if="assignment.selectedLessonCategory">{{
                    assignment.selectedLessonCategory.name
                  }}</small>
                </div>
              </div>
              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1 class="mr-1">{{ assignment.duration }}</h1>
                <span v-if="assignment.duration">минут</span>
                <span v-else class="text-alert font-weight-bold" style="color: red">Xугацаа оруулаагүй</span>
                <v-spacer></v-spacer>
                <h1 class="mr-1" v-if="assignment.finalScore" style="color: red">
                  {{ assignment.finalScore }}
                </h1>
                <span v-if="assignment.finalScore">оноотой</span>
              </v-card-actions>
              <p class="text-sm mt-4 text-body">{{ assignment.description }}</p>
              <hr class="horizontal dark my-4" />

              <v-card-actions class="d-flex flex-row justify-end px-0 pb-4">
                <h1 class="mr-1 green--text" v-if="assignment.result && assignment.result.totalScore">
                  {{ assignment.result.totalScore }}
                </h1>
                <span v-if="assignment.result && assignment.result.totalScore" class="green--text">оноо авсан</span>
                <v-spacer></v-spacer>
                <v-btn color="green" v-if="assignment.examTaken" text @click="_showResult(assignment, false)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-success bg-gradient-success"
                  elevation="0" :ripple="false">Хариулт харах </v-btn>
                <v-btn v-else @click="showWarningAlert(assignment)"
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0" :ripple="false">Сорил өгөx</v-btn>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <h2 class="red--text">Нээлттэй сорил байxгүй байна</h2>
    </section>
    <v-dialog v-if="showSorilResult" v-model="showSorilResult" width="50%">
      <v-card height="100%" class="px-2 pb-10 pt-2">
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3 @click="_print(selectedSoril)">{{selectedSoril.name}} : {{ selectedSoril.courseInfo.COURSE_NAME }}</h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showSorilResult = !showSorilResult"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <StudentLessonAsssignmentResults :resultPath="selectedSoril.result.ref.path" class="py-2 px-4">
        </StudentLessonAsssignmentResults>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import StudentsLessonCard from "./StudentsLessonCard.vue";
import StudentLessonAsssignmentResults from "./StudentLessonAsssignmentResults"
export default {
  components: {
    StudentLessonAsssignmentResults
  },
  data: () => ({
    lessons: null,
    selectedLessons: null,
    teacherSorils: null,
    showSorilResult: false,
    selectedSoril: null
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(
      this.userData["classGroup-" + this.userData.school.currentYear]
    );
    this._setupp();
  },
  methods: {
    _print(assignment){
      console.log(assignment.ref.path)
    },
    _showResult(assignment) {
      this.selectedSoril = assignment
      this.showSorilResult = !this.showSorilResult
      // this.$router.push({
      //   name: "StudentShowAssignmentResults",
      //   params: {
      //     sorilId: assignment.result.ref.path,
      //     fromEYESH: false,
      //   },
      // });
    },
    showWarningAlert(assignment) {
      this.$swal({
        title: "Оролцоxод бэлэн үү?",
        text:
          assignment.title +
          ", " +
          assignment.finalScore +
          " оноотой!" +
          " <Тийм>  гэсэн товч дарсанаар эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        // footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },
    _takeExam(assignment) {
      // this.$router.push({
      //   name: "LessonExamAnswering",
      //   params: {
      //     shalgaltIdd: assignment.selectedExam.ref.path,
      //     noRegister: true,
      //     fromLessonExam: true,
      //     fromLessonExamId: assignment.ref.path,
      //     backRouterPath: this.$route.path,
      //   },
      // });

      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          fromEYESH: false,
          backRouterPath: this.$route.path,
        },
      });

    },
    async _setupp() {
      var studentClassGroupRef =
        this.userData["classGroup-" + this.userData.school.currentYear];
      // if (studentClassGroupRef) {
      //   this.userData.school.ref
      //     .collection("lessons")
      //     .where("isGroupBased", "==", false)
      //     .where("classGroupRefs", "array-contains", studentClassGroupRef)
      //     .onSnapshot((docs) => {
      //       this.lessons = [];
      //       docs.forEach((doc) => {
      //         let lesson = doc.data();
      //         lesson.id = doc.id;
      //         lesson.ref = doc.ref;
      //         console.log(lesson.ref.path, lesson.name);
      //         lesson.exams = null;
      //         lesson.ref
      //           .collection("exams")
      //           .get()
      //           .then((docs) => {
      //             lesson.exams = [];
      //             docs.forEach(async (doc) => {
      //               let exam = doc.data();
      //               exam.id = doc.id;
      //               exam.ref = doc.ref;
      //               await exam.ref
      //                 .collection("results")
      //                 .doc(this.userData.id)
      //                 .get()
      //                 .then((doc) => {
      //                   if (doc.exists) {
      //                     let result = doc.data();
      //                     result.ref = doc.ref;
      //                     result.id = doc.id;
      //                     result.examTaken = true;
      //                     exam.result = result;
      //                     console.log("DONEE~~~");
      //                   } else {
      //                     let result = {};
      //                     exam.examTaken = false;
      //                     exam.result = result;
      //                   }
      //                 });
      //               if (
      //                 exam["assignedToClassGroups"] &&
      //                 exam["assignedToClassGroups"].length > 0
      //               ) {
      //                 for (const classGroup of exam["assignedToClassGroups"]) {
      //                   if (
      //                     classGroup.classGroupRef.path ==
      //                     this.userData["classGroup-2022"].path
      //                   ) {
      //                     lesson.exams.push(exam);
      //                     break;
      //                   }
      //                 }
      //               }
      //             });
      //           });
      //         this.lessons.push(lesson);
      //       });
      //     });
      // }
      if (this.userData.ref) {
        this.userData.school.ref
          .collection("assignmentsdatabase")
          .where("deleted", "==", false)
          .where("openToClassGroupsRefs", "array-contains", studentClassGroupRef)
          .onSnapshot((docs) => {
            this.teacherSorils = []
            docs.forEach(async (doc) => {
              let soril = doc.data();
              soril.id = doc.id;
              soril.ref = doc.ref;
              await soril.ref
                .collection("results")
                .doc(this.userData.id)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    let result = doc.data();
                    result.ref = doc.ref;
                    result.id = doc.id;
                    soril.examTaken = true;
                    soril.result = result;
                  } else {
                    let result = {};
                    soril.examTaken = false;
                    soril.result = result;
                  }
                });
              this.teacherSorils.push(soril)
            })
          })
        // this.userData.ref
        //   .collection("lessons-interested-" + this.userData.school.currentYear)
        //   .onSnapshot(async (docs) => {
        //     this.selectedLessons = [];
        //     docs.forEach(async (doc) => {
        //       console.log(doc.data().lessonCategoryRef.path);
        //       if (this.userData.ref.parent) {
        //         this.userData.ref.parent.parent
        //           .collection("lessons")
        //           .where("deleted", "==", false)
        //           .where("categoryRef", "==", doc.data().lessonCategoryRef)
        //           .onSnapshot((docs) => {
        //             docs.forEach((doc) => {
        //               let lesson = doc.data();
        //               lesson.id = doc.id;
        //               lesson.ref = doc.ref;
        //               this.selectedLessons.push(lesson);
        //             });
        //           });
        //       }
        //     });
        //   });
      }
    },
  },
};
</script>
