var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.result && _vm.loading == false)?_c('v-row',[_c('v-col',{staticClass:"mx-auto"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider'),_vm._l((_vm.categories),function(cat,catindex){return _c('v-tab',{key:catindex},[_vm._v(" "+_vm._s(cat.name)+" ("+_vm._s(_vm.result.categories[catindex].questions.length)+") ")])}),_c('v-spacer'),(_vm.slide)?_c('v-btn',{staticClass:"mb-3 mt-2 text-capitalize bg-gradient-success white--text",on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v("Материал xараx")]):_vm._e()],2)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.categories),function(cat,catindex){return _c('v-tab-item',{key:catindex},[_c('v-row',{staticClass:"mr-4 mt-4",attrs:{"justify":"space-between"}},[_c('span',{staticClass:"ml-10"},[_vm._v("Авсан оноо: ")]),_c('span',{staticClass:"ml-2 font-weight-bold red--text mr-1"},[_vm._v(_vm._s(_vm.result.categories[catindex].catScored)+" ")]),(cat.totalScore)?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("( Аваx оноо: "+_vm._s(cat.totalScore)+")")])]):(cat.groupsEnabled)?_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("( Аваx оноо: "+_vm._s(_vm.getGroupTotalScore(cat))+") ")])]):_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("( Аваx оноо: өгөгдөөгүй) ")])]),_c('v-spacer')],1),_c('v-simple-table',{staticClass:"asuulga-table mt-4",attrs:{"height":"600"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',_vm._l((_vm.result.categories[catindex]
                  .questions),function(question,qindex){return _c('tr',{key:qindex},[(question.skipped)?[_c('td',[(catindex == 0)?_c('span',[_vm._v(_vm._s(qindex + 1))]):_vm._e()]),_c('td',{attrs:{"colspan":"7"}},[_c('small',{staticClass:"red--text"},[_vm._v("Энэ асуултыг тооцоxгүй болсон !!!")])])]:[(_vm.categories[catindex].questions[qindex].deleted !=
                      true
                      )?_c('td',{staticStyle:{"width":"1%","background-color":"#ececec"}},[(catindex == 0)?_c('span',[_vm._v(_vm._s(qindex + 1))]):_c('span',[_vm._v(_vm._s(question.name)+" "+_vm._s(question.skipped))])]):_vm._e(),(_vm.categories[catindex].questions[qindex].deleted !=
                      true &&
                      question.answers.find(
                        (answer) => answer.answered
                      ) == undefined
                      )?_c('td',{staticStyle:{"width":"1%"}},[_c('span',{staticClass:"red--text"},[_vm._v(" Хариулаагүй ")])]):(_vm.categories[catindex].questions[qindex].deleted !=
                      true
                      )?_c('td'):_vm._e(),(_vm.categories[catindex].questions[qindex].deleted != true)?_vm._l((question.answers),function(answer){return _c('td',{key:answer.id,staticClass:"pa-0",class:answer.answered && answer.correct
                        ? 'bordercell'
                        : answer.correct &&
                          question.answers.find(
                            (answer) => answer.answered
                          ) != undefined
                          // ? 'light-green accent-1'
                          ?''
                          : answer.answered
                            ? 'red lighten-4'
                            : '',staticStyle:{"width":"15%"}},[(question.answers.find(
                          (answer) => answer.answered
                        ) != undefined
                          )?_c('span',{domProps:{"innerHTML":_vm._s(answer.answered && answer.correct
                          ? answer.name
                          : answer.correct?
                            answer.name
                            // ? 'Зөв xариулт'
                            : answer.answered
                              ? 'Буруу сонгосон'
                              : answer.name
                          )}}):_vm._e()])}):_vm._e(),(_vm.categories[catindex].questions[qindex].deleted !=
                      true &&
                      question.questionScored &&
                      question.questionScored > 0
                      )?_c('td',{staticClass:"font-weight-bold light-green accent-1",staticStyle:{"width":"2%"}},[_c('span',{staticStyle:{"font-size":"14pt"}},[_vm._v(" "+_vm._s(question.questionScored)+" ")])]):(cat.groupsEnabled &&
                      _vm.categories[catindex].questions[qindex].deleted !=
                      true
                      )?_c('td',{staticClass:"font-weight-bold red lighten-4"},[(_vm.result.categories[catindex].failedGroups &&
                        _vm.result.categories[catindex].failedGroups.find(
                          (failedgroup) =>
                            failedgroup == question.assignedGroup
                        )
                        )?_c('span',[_vm._v("0")]):_c('span',[_vm._v(" "+_vm._s(_vm.getGroupScore(cat, question))+" ")])]):(cat.categoryScoreRequired &&
                      _vm.categories[catindex].questions[qindex].deleted !=
                      true
                      )?_c('td',{staticClass:"font-weight-bold red lighten-4",staticStyle:{"display":"none"}}):(_vm.categories[catindex].questions[qindex].deleted !=
                      true
                      )?_c('td',{staticClass:"font-weight-bold red lighten-4"},[_c('span',{staticClass:"font-weight-bold red--text",staticStyle:{"font-size":"14pt"}},[_vm._v("0")])]):_vm._e()]],2)}),0)]},proxy:true}],null,true)})],1)}),1)],1)],1):_c('v-progress-linear',{staticClass:"mb-4",attrs:{"indeterminate":"","color":"red"}}),_c('v-dialog',{model:{value:(_vm.closeAsuulgaSlide),callback:function ($$v) {_vm.closeAsuulgaSlide=$$v},expression:"closeAsuulgaSlide"}},[_c('v-card',[_c('div',{domProps:{"innerHTML":_vm._s(_vm._getSlideLink())}}),_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('v-btn',{staticClass:"bg-gradient-danger white--text mb-3 mr-3",on:{"click":function($event){_vm.closeAsuulgaSlide = !_vm.closeAsuulgaSlide}}},[_vm._v("Хаах")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }